import React,{Fragment} from 'react';
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import PageHeader from '../components/PageHeader';


export const TeamPageTemplate = ({
  image,
  heading,
  description,
  content,
}) => (
  <Fragment>
    <PageHeader heading={heading} description={description} image={image} />
    <div className="who-we-are">
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-12">
              {/* <h3 className="is-size-2 section-title">
                {content.title}
              </h3>
              <p className="theme-p section-description">{content.text}</p> */}
              <br/>
              <div className="columns is-multiline">
                {content.team.map(item =>{ 
                  return <div key={item.name} className="column is-3">
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <div className="card">
                      <div className="card-image">
                        <figure className="image">
                          <PreviewCompatibleImage imageInfo={item}/>
                        </figure>
                        {!!item.character ? 
                          <div className="character" style={{backgroundImage: `url(${item.character.childImageSharp.fluid.src})`}} alt="Character"></div>
                        : null}
                      </div>
                      <div className="card-content">
                        <p className="title is-4">{item.name}</p> 
                        <p className="subtitle is-6">{item.title}</p>
                        {item.link ? 
                          <div className="media">
                            <div className="media-left">
                              <figure className="image is-48x48">
                              {item.link && <img src='/img/linkedin-icon.png' alt="Linkedin"/>}
                              </figure>
                            </div>
                            <div className="media-content">
                              <p className="subtitle is-6"><a href={item.link} target="_blank" rel="noopener noreferrer">Learn more &raquo;</a></p>
                            </div>
                          </div> 
                        : 
                          <div className="media">
                            <div className="media-left">
                              <figure className="image is-48x48">
                              {<img src='/img/linkedin-icon.png' alt="Linkedin"/>}
                              </figure>
                            </div>
                            <div className="media-content">
                              <p className="subtitle is-6">Coming soon!</p>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </a>
                </div>})}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div className="banner-frame"></div>
  </Fragment>
)

TeamPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.shape({
    team: PropTypes.array,
  })
}

const TeamPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <TeamPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        content={frontmatter.content}
      />
    </Layout>
  )
}

TeamPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default TeamPage

export const pageQuery = graphql`
  query TeamPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "team-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        description
        content {
          title
          text
          team {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            character{
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
            title
            link
          }
        }
      }
    }
  }
`
